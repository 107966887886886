<template>
  <div class="popup_wrap" style="width:900px;">
    <!-- 팝업사이즈 참고 : 500*235  popup_wrap -->
    <button v-show="isCloseBtn" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">Invoice 발행 내역</h1>
      <!-- content_box -->
      <div class="content_box">
        <table class="tbl_col">
          <colgroup>
            <col style="width:12%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:8%">
            <col style="width:10%">
            <col style="width:6%">
            <col style="width:6%">
            <col style="width:10%">
            <col style="width:12%">
            <col style="width:8%">
            <col style="width:8%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">Invoice No.</th>
              <th scope="col">Issue Date</th>
              <th scope="col">Currency</th>
              <th scope="col">Amount</th>
              <th scope="col">DEM/DET</th>
              <th scope="col">Size</th>
              <th scope="col">Type</th>
              <th scope="col">Container Quantity</th>
              <th scope="col">Extend Date</th>
              <th scope="col">Paid</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody v-if="invoiceList.length > 0">
            <tr v-for="(t, i) in invoiceList" :key="'invoice-' + i">
              <td v-if="t.isFirst" :rowspan="t.rowspan"><a href="javascript:void(0)" class="link" @click.prevent="openPopup('demInvoicePop', i)">{{ t.invIssNo }}</a></td>
              <td v-if="t.isFirst" :rowspan="t.rowspan">{{ $ekmtcCommon.changeDatePattern(t.issDt, '.') }}</td>
              <td style="border-left: 1px solid #e1e3eb !important;">{{ t.curCd }}</td>
              <td>{{ t.locAmt | comma }}</td>
              <td>{{ getDemDetCatNm(t.demDetCatCd) }}</td> <!-- DET -->
              <td>{{ t.cntrSzCd }}</td>
              <td>{{ t.cntrTypCd }}</td>
              <td>{{ t.cntrQty }}</td>
              <td>{{ $ekmtcCommon.changeDatePattern(t.extsDt, '.') }}</td>
              <td>{{ t.rcvCpltYn === 'Y'? 'paid' : 'No paid' }}</td>
              <td>
                <span v-if="t.apvStsCd === '요청' || t.apvStsCd === '승인' || t.apvStsCd === '거절' || t.apvStsCd === '처리중' || t.apvStsCd === '취소'">
                  <a href="javascript:void(0)" class="link" @click.prevent="openPopup('CalcIssueInvMsgPop', i)">{{ t.apvStsCd }}</a>
                </span>
                <span v-else>{{ t.apvStsCd }}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="11">No data</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- content_box // -->
      <div class="mt10 text_center">
        <a class="button blue lg" href="javascript:void(0)" @click.prevent="$emit('close')">{{ $t('msg.CMBA100.00261') }}</a>
      </div>
      <win-layer-pop class="sample_popup_list">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="parentInfoRe"
        />
      </win-layer-pop>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import searchDemurrageDetailForm from '@/api/rest/settle/searchdemurrageDetailForm'
import demInvoicePop from '@/pages/settle/popup/DemInvoicePop'

export default {
  name: 'PreviousIssuedInvoicePop',
  components: {
    demInvoicePop,
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop')
  },
  filters: {
    comma (val) {
      var num = Number(val)
      return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
          return {
              blNo: '',
              demDetCatCd: '',
              eiCatCd: '',
              lang: '',
              cstCd: ''
          }
      }
    }
  },
  data () {
      return {
        params: { },
        invoiceList: [],
        parentInfoRe: {},
        customComponent: null,
        isCloseBtn: true
      }
  },
  created () {
      console.log('getInvoicePop created: ')
      // console.log('this.invoicePop.blNo: ', this.parentInfo.blNo)
      // console.log('this.invoicePop.demDetCatCd: ', this.parentInfo.demDetCatCd)
      // console.log('this.invoicePop.eiCatCd: ', this.parentInfo.eiCatCd)

      this.params.blNo = this.parentInfo.blNo
      this.params.demDetCatCd = this.parentInfo.demDetCatCd
      this.params.eiCatCd = this.parentInfo.eiCatCd

      // console.log('blNo', this.params.blNo)
      // console.log('demDetCatCd', this.params.demDetCatCd)
      // console.log('eiCatCd', this.params.eiCatCd)

      this.getInvoice()
  },
  mounted () {
      // this.getInvoice()
  },
  methods: {
    getInvoice () {
      console.log('params: ', this.params)
      searchDemurrageDetailForm.getInvoice(this.params).then(response => {
        console.log('response ::: ', response)
        const data = response.data
        this.invoiceList = this.parseInvoiceList(data.rstList)
        console.log('resp.rstCnt: ', data.rstCnt)
        console.log('resp.rstList: ', data.rstList)
      })
    },
    parseInvoiceList (list) {
      let res = []
      for (let i = 0; i < list.length; i++) {
        list[i].isFirst = true
        list[i].rowspan = 1
        for (let j = 0; j < res.length; j++) {
          if (list[i].invIssNo === res[j].invIssNo) {
            list[i].isFirst = false
            res[j].rowspan++
            break
          }
        }
        res.push(list[i])
      }
      console.log(res)
      return res
    },
    openPopup (compNm, idx) {
      if (compNm === 'CalcIssueInvMsgPop') {
        this.$emit('child', {
          type: 'openPopup',
          payload: {
            compNm: compNm,
            idx: idx,
            freeTimeInvoice: JSON.parse(JSON.stringify(this.invoiceList[idx]))
          }
        })
      } else {
        if (this.customComponent !== null) {
          this.customComponent = null
          this.$ekmtcCommon.layerClose()
        }
        this.parentInfoRe = {
          invoice: JSON.parse(JSON.stringify(this.invoiceList[idx])),
          blNo: this.parentInfo.blNo,
          lang: this.parentInfo.lang,
          usrNm: this.parentInfo.usrNm,
          ctrCd: this.parentInfo.ctrCd,
          demDetCatCd: this.parentInfo.demDetCatCd,
          eiCatCd: this.parentInfo.eiCatCd
        }
        this.isCloseBtn = false
        this.customComponent = compNm
        this.$ekmtcCommon.layerOpen('.sample_popup_list')
      }
    },
    closePopup (obj) {
      this.isCloseBtn = true
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.sample_popup_list')
    },
    getDemDetCatNm (cd) {
      if (cd === '01') {
        return 'DEM'
      } else if (cd === '02') {
        return 'DET'
      } else if (cd === '03') {
        return 'COM'
      } else if (cd === '04') {
        return 'MOR'
      } else if (cd === '14') {
        return 'OFC'
      } else {
        return ''
      }
    }
  }

    }
</script>

<style lang="scss" scoped>

</style>
