<template>
  <div class="popup_wrap" style="width:500px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title mt0">{{ pageTitle }}</h1>
      <div class="content_box">
        <div v-if="process.state !== ''">
          <h4>{{ process.state }}</h4>
          <div>{{ process.reason }}</div>
        </div>
        <br>
        <h4>[{{ $t('msg.ONIM070P020.005') }}]</h4> <!-- 담당자 -->
        <div>{{ $t('msg.SETT010T010.032') }} : {{ process.user }}</div>
        <div>{{ $t('msg.SETT010T010.033') }} : {{ process.address }}</div>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import calcIssueApi from '@/api/rest/settle/calcIssue'
import { rootComputed } from '@/store/helpers'
import myRequest from '@/api/rest/trans/myRequest'
import boards from '@/api/services/boards'

export default {
  name: 'CalcIssueInvMsgPop',
  components: {},
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          apvStsCd: '',
          reqRspsLst: '',
          blNo: ''
        }
      }
    }
  },
  data: function () {
    return {
      pageTitle: '', //'제목',
      process: {
        state: '',
        reason: '',
        user: '',
        address: ''
      }
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.pageTitle = '처리상태 및 담당자'
    this.setData()
  },
  mounted () {
  },
  methods: {
    setData () {
      this.displayMsg()
    },
    async displayMsg () {
      // 상태 코드 구분하여 상태명으로 변환
      this.process.state = this.getApvStsCdText(this.parentInfo.apvStsCd)

      if (this.process.state !== '') {
        switch (this.parentInfo.apvStsCd) {
          case '03' :
            this.process.reason = '승인되었습니다.'
            break
          case '02' :
            this.process.reason = 'OFC 요청 건, 순차적으로 처리 중입니다. 잠시 기다려 주시기 바랍니다.'
            break
          default :
            this.process.reason = this.parentInfo.reqRspsLst
            break
        }
      }

      let plcCd = ''
      await calcIssueApi.getInvApvUser(this.parentInfo.blNo).then(response => {
          this.process.user = response.data.usrNm
          plcCd = response.data.plcCd
      })

      if (plcCd === 'SEL') {
        this.process.address = this.$t('msg.KMTC118.006') //'서울특별시 중구 남대문로2가 118 한진빌딩 15층(신관) 고려해운 DESK'
      } else if (plcCd === 'PUS') {
        this.process.address = this.$t('msg.KMTC118.007') //'부산광역시 중구 중앙동4가 88-7 교보생명빌딩 10층'
      } else if (plcCd === 'USN') {
        this.process.address = this.$t('msg.KMTC118.008') // '울산광역시 남구 용연로 370 (황성동) 울산동방아이포트 4층'
      } else if (plcCd === 'INC') {
        this.process.address = this.$t('msg.KMTC118.010') //'인천 광역시 중구 신흥동 3가 7-241 정석빌딩 신관 8층'
      }
    },
    getApvStsCdText (cd) {
      let res = ''
      // Pending, Reject, Cancel 인 경우만 표기하도록 SR 요구사항이 있어, 그대로 처리함. 2023.10.23 dsyang
      // Requested, Accepted 경우 추가.
      switch (cd) {
        case '01':
          res = ''
          break
        case '02':
          res = '[Requested 사유]'
          break
        case '03':
          res = '[Accepted 사유]'
          break
        case '04':
          res = '[Rejected 사유]'
          break
        case '05':
          res = ''
          break
        case '06':
          res = ''
          break
        case '07':
          res = '[Pending 사유]'
          break
        case '08':
          res = ''
          break
        case '09':
          res = ''
          break
        case '10':
          res = '[Cancelled 사유]'
          break
      }
      return res
    }
  }
}
</script>
