import Send from '../../settle.client.js'

export default {

    getFreetime (param) {
        return Send({
            url: '/settle/freetime',
            // url: 'http://localhost:3000/settle/freetime',
            method: 'get',
            params: {
                blNo: param.blNo,
                cntrNo: param.cntrNo,
                dtKind: param.dtKind,
                eiCatCd: param.eiCatCd
            }
        })
    },

    overdayInquiry (param) {
        return Send({
            // url: 'http://localhost:9008/settle/freetime',
            url: '/settle/freetime',
            method: 'get',
            params: {
                blNo: param.blNo,
                cntrNo: param.cntrNo,
                demDetCatCd: param.demDetCatCd,
                eiCatCd: param.eiCatCd,
                extsDt: param.extsDt
            }
        })
    },

    getInvoice (param) {
        return Send({
            //url: 'http://localhost:9008/settle/freetime/invoice',
            url: '/settle/freetime/invoice',
            method: 'get',
            params: {
                blNo: param.blNo,
                demDetCatCd: param.demDetCatCd,
                eiCatCd: param.eiCatCd
            }
        })
    }
}
