var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "500px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title mt0" }, [
          _vm._v(_vm._s(_vm.pageTitle)),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _vm.process.state !== ""
            ? _c("div", [
                _c("h4", [_vm._v(_vm._s(_vm.process.state))]),
                _c("div", [_vm._v(_vm._s(_vm.process.reason))]),
              ])
            : _vm._e(),
          _c("br"),
          _c("h4", [_vm._v("[" + _vm._s(_vm.$t("msg.ONIM070P020.005")) + "]")]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              _vm._s(_vm.$t("msg.SETT010T010.032")) +
                " : " +
                _vm._s(_vm.process.user)
            ),
          ]),
          _c("div", [
            _vm._v(
              _vm._s(_vm.$t("msg.SETT010T010.033")) +
                " : " +
                _vm._s(_vm.process.address)
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }